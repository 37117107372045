import moment from "moment";
import { Loading } from "element-ui";
import to_boolean from "nllib/src/convert/to_boolean";
// 城市组件
import TravelCitySelector from "yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/travel-city-selector/1.1.1/index.vue";
// 旅游产品列表组件
import ProductList from "@/page/distributor/tour/front/search/component/product-list/index.vue";
// 获取定位城市
import get_local_city from "@/lib/common-service/get_local_city/index.js";

//搜索
import web_common_userBuyer_travelSearch from "@/lib/data-service/default/web_common_userBuyer_travelSearch";
// 热门景点
import web_common_visitor_getHotAttractions from "@/lib/data-service/default/web_common_visitor_getHotAttractions";
// 团期
import web_common_userBuyer_GroupStage from "@/lib/data-service/default/web_common_userBuyer_GroupStage";
// 通过ip获取定位城市
import web_common_TourismMall_getArea from "@/lib/data-service/default/web_common_TourismMall_getArea";
export default {
  components: {
    // 城市组件
    TravelCitySelector,
    // 产品列表组件
    ProductList,
  },
  data() {
    return {
      hotLoading: false,
      moment,
      // 是否带注册地城市搜索
      is_auto_add_city: true,
      //
      loading: false,
      // 是否第一次搜索
      isFirst: true,
      // 是否通过改变区域类型搜索
      isChangeArea: false,
      // 是否通过改变 类别搜索
      isChangeType: false,

      loadingInstance: null,
      // 注册地城市名和id
      cityId: "",
      cityName: "",
      // 为空显示类型
      isEmpty: false,
      // 搜索条件展示
      navList: [],
      // 选中出发城市列表
      fromCityArr: [],
      //
      fromCityArr1: [],
      // 选中到达城市列表
      toCityArr: [],
      // 搜索参数
      form: {
        page: 1,
        size: 10,
        productType: 2,
        fromCity: "", //出发城市
        fromCityId: "",
        toCity: "", //目的城市
        toCityId: "",
        areaType: "",
        managementType: "",
        productLevel: "",
        isSpecialSupply: 0,
        tripDay: "",
        startTime: "",
        endTime: "",
        price: "",
        trafficMode: "",
        hotelLevel: "",
        haveShopping: "",
        haveSelfItem: "",
        collectGuestType: "",
        priceSort: "",
        salesVolumeSort: "",
        onlyShowSpecial: "0",
        travelKeywords: "",
      },
      allocation: {
        isFold: true,
        areaTypeOptions: [
          { label: "全部", value: "" },
          { label: "国内游", value: "1" },
          { label: "出境游", value: "2" },
        ],
        managementTypeOptions: [
          { label: "", value: "全部" },
          { label: 1, value: "跟团游" },
          { label: 2, value: "自由行" },
          { label: 3, value: "当地游" },
        ],
        productLevelOptions: [
          { value: 1, text: "经典" },
          { value: 2, text: "经济" },
          { value: 3, text: "促销" },
          { value: 4, text: "尊享" },
          { value: 6, text: "臻品" },
        ],
        tripDayOptions: [
          { value: 1, text: "1天" },
          { value: 2, text: "2天" },
          { value: 3, text: "3天" },
          { value: 4, text: "4天" },
          { value: 5, text: "5天" },
          { value: 6, text: "6天" },
          { value: 7, text: "7天" },
          { value: 8, text: "8天及以上" },
        ],
        tripDayMore: false,
        tripDayList: [],
        depDateOptions: [0, 1, 2, 3, 4, 5],
        priceOptions: [
          { value: "0-1000", text: "1000元以下" },
          { value: "1000-2000", text: "1000-2000元" },
          { value: "2001-4000", text: "2001-4000元" },
          { value: "4001-6000", text: "4001-6000元" },
          { value: "6000-*", text: "6000元以上" },
        ],
        minPrice: "",
        maxPrice: "",
        startDate: "",
        endDate: "",
        trafficModeOptions: [
          { value: 1, text: "航空" },
          { value: 2, text: "高铁" },
          { value: 3, text: "动车" },
          { value: 4, text: "火车" },
          { value: 5, text: "旅游大巴" },
          { value: 6, text: "其他" },
        ],
        trafficModeMore: false,
        trafficModeList: [],
        hotelLevelOptions: [
          { value: 1, text: "民宿 " },
          { value: 2, text: "二星级以下" },
          { value: 3, text: "准三星" },
          { value: 4, text: "三星级" },
          { value: 5, text: "准四星级" },
          { value: 6, text: "四星级" },
          { value: 7, text: "准五星级" },
          { value: 8, text: "五星级" },
        ],
        haveShoppingOptions: [
          { value: 1, text: "有购物" },
          { value: 2, text: "无购物" },
        ],
        haveSelfItemOptions: [
          { value: 1, text: "有自费" },
          { value: 2, text: "无自费" },
        ],
        collectGuestTypeOptions: [
          { value: 1, text: "散拼团" },
          { value: 2, text: "小包团" },
        ],
        sortType: "",
        sortOptions: [
          { label: "", value: "综合排序" },
          { label: "priceSort", value: "价格排序", isUp: false },
          { label: "salesVolumeSort", value: "销量排序", isUp: false },
        ],
      },
      pager: {
        pageSizes: [10, 15, 20, 25, 30],
        total: 0,
      },
      // 产品列表
      productListData: [],
      // 推荐产品
      recommendList: [],
      // 热门城市列表
      hotCityListData: [],
    };
  },
  methods: {
    req_datas(city) {
      const form = this.form;
      const query = this.$route.query;

      this.when_onresize();

      this.cityId = city.areaId;
      this.cityName = city.cityName;

      const arr = [
        "is_auto_add_city",
        "fromCItyId",
        "fromCityName",
        "toCityId",
        "toCityName",
      ];
      for (const key in query) {
        if (query[key] && !arr.includes(key)) {
          form[key] = query[key];
        }
      }

      if (query.hasOwnProperty("is_auto_add_city"))
        this.is_auto_add_city = to_boolean(query.is_auto_add_city, true);

      if (query.travelKeywords)
        this.add_nav_list("travelKeywords", "关键字", query.travelKeywords);

      if (this.is_auto_add_city) {
        if (this.cityId && form.areaType.toString() !== "2") {
          // 延迟用来解决城市组件还没加载完
          const managementType = form.managementType.toString();
          if (managementType === "3") {
            //做目的城市

            form.toCity = this.cityName;
            form.toCityId = this.cityId;
            this.add_nav_list("toCity", "目的城市", form.toCity);
          } else {
            //做出发城市

            form.fromCity = this.cityName;
            form.fromCityId = this.cityId;
          }
          //当带城市
          if (form.fromCity)
            this.fromCityArr = [
              ...this.cityId
                .toString()
                .split(",")
                .map((item) => (item = Number(item))),
            ];
          if (form.toCity)
            this.toCityArr = [
              ...this.cityId
                .toString()
                .split(",")
                .map((item) => (item = Number(item))),
            ];
          this.req_search_product({ ...form });
        } else {
          this.req_search_product();
        }
      } else {
        const params = { ...form };
        if (query.fromCityName) {
          this.add_nav_list("fromCity", "出发城市", query.fromCityName);

          this.fromCityArr = [
            ...query.fromCityId
              .toString()
              .split(",")
              .map((item) => (item = Number(item))),
          ];
          this.fromCityArr1 = this.fromCityArr;
          params.fromCity = query.fromCityName;
          form.fromCityId = query.fromCityId;
          form.fromCity = query.fromCityName;
        }
        if (query.toCityName) {
          this.add_nav_list("toCity", "目的城市", query.toCityName);
          this.toCityArr = [
            ...query.toCityId
              .toString()
              .split(",")
              .map((item) => (item = Number(item))),
          ];
          params.toCity = query.toCityName;
          params.toCityId = query.toCityId;
          form.toCity = query.toCityName;
          form.toCityId = query.toCityId;
        }
        this.req_search_product(params);
      }
    },
    // 请求热门景点
    req_hot_scenery_spot() {
      this.hotLoading = true;
      web_common_visitor_getHotAttractions()
        .then((result) => {
          this.hotCityListData = result.hotCitys;
          this.hotLoading = false;
        })
        .catch((err) => {
          this.hotLoading = false;
        });
    },

    init() {
      this.loading = true;
      // 热门景点
      this.req_hot_scenery_spot();

      this.add_nav_list("managementType", "产品类型", "全部");
      const localCity = get_local_city();

      if (localCity.cityId) {
        this.req_datas({
          areaId: localCity.cityId,
          cityName: localCity.cityName,
        });
      } else {
        web_common_TourismMall_getArea()
          .then((result) => {
            this.req_datas(result);
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    req_search_product(form = this.form) {
      this.loading = true;

      web_common_userBuyer_travelSearch(form)
        .then((res) => {
          const list = res.pageResult;

          list.forEach((item) => {
            item.isMore = false;
            item.productLeve = item.specMap.spec_productLevel;
          });

          this.pager.total = Number(res.total);
          this.productListData = list;

          const list1 = res.recommendPageResult;
          if (list1 && list1.length) {
            list1.forEach((item) => {
              item.isMore = false;
              item.productLeve = item.specMap.spec_productLevel;
            });
            this.recommendList = list1;
          } else {
            this.recommendList = [];
          }

          this.loading = false;
          if (this.isChangeArea) this.isChangeArea = false;
          if (this.isChangeType) this.isChangeType = false;
          if (this.isFirst) this.isFirst = false;
        })
        .catch((err) => {
          this.loading = false;
          if (this.isChangeArea) this.isChangeArea = false;
          if (this.isChangeType) this.isChangeType = false;
          if (this.isFirst) this.isFirst = false;
        });
    },

    register_head_click() {
      // 在当前页面点击头部来进行搜索
      PubSub.subscribe("head", (msg, data) => {
        const form = this.form;
        if (!data.hasOwnProperty("isSpecialSupply")) form.isSpecialSupply = 0;
        for (const key in data) {
          form[key] = data[key];
        }
        this.change_page(1);
      });
    },

    search() {
      this.form.currentPage = 1;
      this.req_search_product();
    },
    when_onresize() {
      window.onscroll = this.hot_list_float;
    },
    hot_list_float() {
      const child = this.$refs.hotCity;
      if (!this.productListData.length) {
        child.style.position = "relative";
        child.style.top = "0";
        child.style.left = "0";
        return;
      }

      const parent = this.$refs.cityWrap;
      const parentTop = parent.getBoundingClientRect().top;
      const parentBottom = parent.getBoundingClientRect().bottom;
      const parentLeft = parent.getBoundingClientRect().left;
      const parentHeight = parent.getBoundingClientRect().height;

      const childTop = child.getBoundingClientRect().top;
      const childHeight = child.getBoundingClientRect().height;

      const fixedTop = 60;

      if (parentTop >= fixedTop) {
        child.style.position = "relative";
        child.style.top = "0";
        child.style.left = "0";
      } else if (
        parentTop < fixedTop &&
        parentBottom > childHeight + fixedTop
      ) {
        child.style.position = "fixed";
        child.style.top = `${fixedTop}px`;
        child.style.left = `${parentLeft}px`;
      } else {
        child.style.position = "absolute";
        child.style.top = `${parentHeight - childHeight}px`;
        child.style.left = "0";
      }
    },
    fold_open() {
      this.allocation.isFold = !this.allocation.isFold;
    },
    add_nav_list(key, name, text) {
      const navList = this.navList;
      const nav = navList.filter((item) => item.key === key);
      if (nav.length) {
        nav[0].text = text;
      } else {
        const obj = { key, name, text };
        navList.push(obj);
      }
    },
    handle_close_nav(key) {
      this.is_auto_add_city = false;
      if (key === "managementType") {
        if (this.form.managementType !== "")
          this.add_nav_list("managementType", "产品类型", "全部");
        return;
      }
      const form = this.form;
      const navList = this.navList;
      const allocation = this.allocation;

      for (let i = 0; i < navList.length; i++) {
        const item = navList[i];
        if (item.key === key) {
          navList.splice(i, 1);
        }
      }

      if (key === "travelKeywords") {
        PubSub.publish("travelKeywords", "");
        form[key] = "";
      }

      if (key === "isSpecialSupply") {
        // form.isSpecialSupply = 0;
      } else if (key === "toCity") {
        this.is_auto_add_city = false;
        this.toCityArr = [];
      } else if (key === "fromCity") {
        this.is_auto_add_city = false;
        this.fromCityArr = [];
        this.fromCityArr1 = [];
      } else if (key === "depDate") {
        form.startTime = "";
        form.endTime = "";
        allocation.startDate = "";
        allocation.endDate = "";
      } else {
        if (key === "price") {
          allocation.minPrice = "";
          allocation.maxPrice = "";
        }
        form[key] = "";
      }

      if (!["fromCity", "toCity"].includes(key)) {
        this.search();
      }
    },
    radio_search_option(key, name, value, text) {
      this.add_nav_list(key, name, text);
      this.form[key] = value;
      this.search();
    },
    more_select(key) {
      const allocation = this.allocation;
      allocation[key] = true;
    },
    // 多选
    commit_more_select(option, key, list) {
      const form = this.form;
      const allocation = this.allocation;
      allocation[list].sort(function(a, b) {
        return a - b;
      });
      if (option === "tripDay") {
        const value = allocation[list]
          .map(function(item) {
            if (item === 8) {
              return item + "天及以上";
            } else {
              return item + "天";
            }
          })
          .join();
        if (value) this.add_nav_list(option, "行程天数", value);
      } else if (option === "trafficMode") {
        const value = allocation[list]
          .map(function(item) {
            switch (item) {
              case 1:
                return "航空";
                break;
              case 2:
                return "高铁";
                break;
              case 3:
                return "动车";
                break;
              case 4:
                return "火车";
                break;
              case 5:
                return "旅游大巴";
                break;
              case 6:
                return "其他";
                break;
            }
          })
          .join();
        if (value) this.add_nav_list(option, "出行方式", value);
      }
      form[option] = allocation[list].join();
      allocation[key] = false;
      allocation[list] = [];
      this.search();
    },
    // 关闭多选
    cancel_more_select(key, list) {
      const allocation = this.allocation;
      allocation[key] = false;
      allocation[list] = [];
    },
    managementType_chang(value) {
      let text = "";
      switch (value) {
        case "":
          text = "全部";
          break;
        case 1:
          text = "跟团游";
          break;
        case 2:
          text = "自由行";
          break;
        case 3:
          text = "当地游";
          break;
      }
      this.add_nav_list("managementType", "产品类型", text);
    },
    change_depDate(nowMonth) {
      const form = this.form;
      const start = moment()
        .add(nowMonth, "M")
        .set("date", 1)
        .format("YYYY-MM-DD");
      const end = moment(start)
        .add(1, "month")
        .add(-1, "days")
        .format("YYYY-MM-DD");
      this.add_nav_list(
        "depDate",
        "出发日期",
        `${moment(start).format("MM")}月`
      );
      form.startTime = start;
      form.endTime = end;
      this.search();
    },
    startDate_change(date) {
      const allocation = this.allocation;
      const now = new Date();
      if (date < now) {
        date = now;
        allocation.startDate = date;
      }
      if (allocation.endDate < date) {
        allocation.endDate = date;
      }
    },
    endDate_change(date) {
      const allocation = this.allocation;
      const now = new Date();
      if (date < now) {
        date = now;
        allocation.endDate = date;
      }
      if (allocation.startDate > date || !allocation.startDate) {
        allocation.startDate = date;
      }
    },
    commit_date() {
      const form = this.form;
      const allocation = this.allocation;
      if (!allocation.startDate) {
        this.$message({
          message: "请输入开始时间",
          type: "warning",
        });
        return;
      }
      if (!allocation.endDate) {
        this.$message({
          message: "请输入结束时间",
          type: "warning",
        });
        return;
      }
      const start = moment(allocation.startDate).format("YYYY-MM-DD");
      const end = moment(allocation.endDate).format("YYYY-MM-DD");
      this.add_nav_list("depDate", "出发日期", `${start} + ${end}`);
      form.startTime = start;
      form.endTime = end;
      this.search();
    },
    reset_date() {
      const form = this.form;
      const allocation = this.allocation;
      if (!allocation.startDate && !allocation.endDate) return;
      const navList = this.navList;
      form.startTime = "";
      form.endTime = "";
      allocation.startDate = "";
      allocation.endDate = "";
      navList.forEach(function(item, index) {
        if (item.key === "depDate") {
          navList.splice(index, 1);
        }
      });
      this.search();
    },
    price_change(key) {
      const allocation = this.allocation;
      const reg = /\D/g;
      allocation[key] = allocation[key].replace(reg, "");
      if (allocation[key] <= 0) {
        allocation[key] = 0;
      }
    },
    commit_price() {
      const form = this.form;
      const allocation = this.allocation;
      if (!allocation.minPrice) {
        this.$message({
          message: "请输入最低价格",
          type: "warning",
        });
        return;
      }
      if (!allocation.maxPrice) {
        this.$message({
          message: "请输入最高价格",
          type: "warning",
        });
        return;
      }
      if (allocation.minPrice > allocation.maxPrice) {
        this.$message({
          message: "请输入正确的价格区间",
          type: "warning",
        });
        return;
      }
      const price = `${allocation.minPrice} - ${allocation.maxPrice}`;

      this.add_nav_list("price", "产品价格", price + "元");

      form.price = price;
      this.search();
    },
    reset_price() {
      const form = this.form;
      const allocation = this.allocation;
      if (!allocation.minPrice && !allocation.maxPrice) return;
      const navList = this.navList;
      form.price = "";
      allocation.minPrice = "";
      allocation.maxPrice = "";
      navList.forEach(function(item, index) {
        if (item.key === "price") {
          navList.splice(index, 1);
        }
      });
      this.search();
    },
    change_sortType(newVal, oldVal) {
      const _this = this;
      const form = _this.form;
      const allocation = _this.allocation;
      allocation.sortOptions.forEach(function(item) {
        allocation.sortType = newVal;
        if (item.label === newVal) {
          if (item.label === "") {
            form.priceSort = "";
            form.salesVolumeSort = "";
          } else {
            if (newVal === oldVal) {
              item.isUp = !item.isUp;
            }
            form[newVal] = item.isUp ? "asc" : "desc";
          }
        } else {
          if (item.label === "") return;
          form[item.label] = "";
        }
      });

      this.req_search_product();
    },
    change_onlyShowSpecial() {
      const form = this.form;
      if (form.onlyShowSpecial === "0") {
        form.onlyShowSpecial = "4";
      } else if (form.onlyShowSpecial === "4") {
        form.onlyShowSpecial = "0";
      }
      this.req_search_product();
    },
    handleSizeChange(size) {
      this.form.size = size;
      this.change_page(1);
    },
    change_page(page) {
      document.documentElement.scrollTop = 0;
      this.form.page = page;
      this.req_search_product();
    },
    to_details(query) {
      PubSub.publish("travelKeywords");
      const { href } = this.$router.resolve({
        name: "distributor-tour-front-details",
        query,
      });
      window.open(href, "_blank");
    },
    to_details_team(productNo, startTime) {
      startTime = moment(startTime).format("YYYY-MM-DD");
      const params = { productNo, startTime };
      this.to_details(params);
    },
    to_reserve(query) {
      const { href } = this.$router.resolve({
        name: "distributor-tour-front-reserve",
        query,
      });
      window.open(href, "_blank");
    },
    to_reserve_team(productNo, startTime, tourTeamId) {
      startTime = moment(startTime).format("YYYY-MM-DD");
      const params = { productNo, startTime, tourTeamId };
      this.to_reserve(params);
    },
    to_search(travelKeywords) {
      PubSub.publish("travelKeywords", travelKeywords);
      this.form.travelKeywords = travelKeywords;
      document.documentElement.scrollTop = 0;
      this.change_page(1);
    },
    to_more(product) {
      product.currentPage = 1;
      const params = { currentPage: 1, productNo: product.productNo };
      web_common_userBuyer_GroupStage(params)
        .then((res) => {
          product.teamData = res.pageResult.pageData;
          product.hasNext = res.pageResult.hasNext;
          product.isMore = true;
        })
        .catch((err) => {});
    },
    get_more_team(product) {
      if (!product.hasNext) return;
      product.currentPage += 1;
      web_common_userBuyer_GroupStage({
        productNo: product.productNo,
        currentPage: product.currentPage,
      })
        .then((res) => {
          product.hasNext = res.pageResult.hasNext;
          product.teamData.push(...res.pageResult.pageData);
        })
        .catch((err) => {});
    },
    fold_more(product, node) {
      const productTop = node.getBoundingClientRect().top;
      if (productTop < 0) {
        document.documentElement.scrollTop += productTop;
      }
      product.isMore = false;
    },
  },
  watch: {
    loading(val) {
      if (val) {
        this.loadingInstance = Loading.service();
      } else {
        // isHeadSearch用来判断防止重复请求在当前页面点击头部时，请求完后需要把isHeadSearch改成false；
        const store = this.$store;
        if (store.state.baseLayout.isHeadSearch)
          store.commit("update_isHeadSearch", false);
        this.loadingInstance.close();
      }
    },

    "form.travelKeywords"(val) {
      if (val) {
        this.add_nav_list("travelKeywords", "关键字", val);
      }
    },

    "form.managementType"(val, old) {
      val = Number(val);
      old = Number(old);
      if (val === 3 || old === 3) {
        this.isChangeType = true;
        const form = this.form;
        const areaType = Number(form.areaType);
        if (areaType !== 2) {
          const cityId = this.cityId;
          if (val === 3) {
            if (form.fromCity) {
              this.fromCityArr = [];
              form.fromCity = "";
            }
            if (!form.toCity) {
              if (!this.isFirst && this.is_auto_add_city) {
                this.toCityArr = [cityId];
                form.toCity = this.cityName;
              }
            }
          } else {
            if (form.toCity) {
              this.toCityArr = [];
              form.toCity = "";
            }
            if (!form.fromCity && this.is_auto_add_city) {
              this.fromCityArr = [cityId];
              form.fromCity = this.cityName;
            }
          }
        }
      }

      if (!this.isFirst) this.change_page(1);
    },

    "form.areaType"(val, old) {
      if (!this.isFirst) {
        val = Number(val);
        old = Number(old);
        const cityId = this.cityId;
        const form = this.form;
        const managementType = Number(form.managementType);
        if (val === 2 || old === 2) {
          this.isChangeArea = true;
          if (val === 2) {
            if (form.fromCity) {
              this.fromCityArr = [];
              form.fromCity = "";
            }
            if (form.toCity) {
              this.toCityArr = [];
              form.toCity = "";
            }
          } else {
            if (managementType === 3 && this.is_auto_add_city) {
              this.toCityArr = [cityId];
              form.toCity = this.cityName;
            } else {
              if (form.toCity) {
                this.toCityArr = [];
                form.toCity = "";
              }

              if (!form.fromCity && this.is_auto_add_city) {
                this.fromCityArr = [cityId];
                form.fromCity = this.cityName;
              }
            }
          }
        }
      }

      // isHeadSearch用来判断防止重复请求在当前页面点击头部时，请求完后需要把isHeadSearch改成false；
      if (this.isFirst) return;
      if (this.$store.state.baseLayout.isHeadSearch) return;
      this.search();
    },

    fromCityArr: {
      handler(val) {
        this.$nextTick(() => {
          if (this.is_auto_add_city) {
            const form = this.form;

            if (["", 1, 2].includes(form.managementType) && !val.length) {
              this.is_auto_add_city = false;
            }

            form.fromCityId = val.join();
            if (!val.length) {
              this.navList.forEach((item, index, arr) => {
                if (item.key === "fromCity") arr.splice(index, 1);
              });
              form.fromCity = "";
            } else {
              const list = this.$refs.fromCity.get_selected_entity_list({
                latest_entity_id_list: val,
              });
              const arr = list.map((item) => item.areaName);
              if (this.isFirst && this.is_auto_add_city)
                this.add_nav_list("fromCity", "出发城市", this.cityName);
              if (!this.isFirst) {
                form.fromCity = arr.join(",");
                this.add_nav_list("fromCity", "出发城市", form.fromCity);
                form.fromCity = arr.join();
              }
            }
            if (!this.isFirst && !this.isChangeArea && !this.isChangeType)
              this.change_page(1);
            this.isFirst = false;
          }
        });
      },
      deep: true,
    },

    fromCityArr1: {
      handler(val) {
        if (!this.is_auto_add_city) {
          this.$nextTick(() => {
            const form = this.form;
            form.fromCityId = val.join();
            if (!val.length) {
              this.navList.forEach((item, index, arr) => {
                if (item.key === "fromCity") arr.splice(index, 1);
              });
              form.fromCity = "";
            } else {
              const list = this.$refs.fromCity1.get_selected_entity_list({
                latest_entity_id_list: val,
              });
              const arr = list.map((item) => item.areaName);
              if (this.isFirst && this.is_auto_add_city)
                this.add_nav_list("fromCity", "出发城市", this.cityName);
              if (!this.isFirst) {
                form.fromCity = arr.join(",");
                this.add_nav_list("fromCity", "出发城市", form.fromCity);
                form.fromCity = arr.join();
              }
            }
            if (!this.isFirst && !this.isChangeArea && !this.isChangeType)
              this.change_page(1);
            this.isFirst = false;
          });
        }
      },
      deep: true,
    },
    toCityArr: {
      handler(val) {
        this.$nextTick(() => {
          if (this.isFirst) return;
          const form = this.form;

          if ([3].includes(form.managementType) && !val.length) {
            this.is_auto_add_city = false;
          }
          form.toCityId = val.join();
          if (!val.length) {
            this.navList.forEach((item, index, arr) => {
              if (item.key === "toCity") arr.splice(index, 1);
            });
            form.toCity = "";
          } else {
            const list = this.$refs.toCity.get_selected_entity_list({
              latest_entity_id_list: val,
            });
            const arr = list.map((item) => item.areaName);
            if (!this.isFirst) {
              form.toCity = arr.join();
              this.add_nav_list("toCity", "目的城市", this.form.toCity);
            }
          }

          if (!this.isFirst && !this.isChangeArea && !this.isChangeType)
            this.change_page(1);
          this.isFirst = false;
        });
      },
      deep: true,
    },
  },
  filters: {
    format_departDate(value) {
      return moment()
        .add(value, "M")
        .format("MM");
    },
  },
  mounted() {
    this.register_head_click();
    this.init();
  },
  beforeDestroy() {
    window.onscroll = null;
    this.loading = false;
    this.loadingInstance.close();
  },
};
