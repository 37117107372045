var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list_wrap"},[_c('ul',{staticClass:"product_list"},_vm._l((_vm.list),function(product,key){return _c('li',{key:key,ref:"product",refInFor:true,staticClass:"product_item"},[_c('div',{staticClass:"product_img"},[(
              product.specMap.spec_specialSort !== '4' &&
              product.specMap.spec_specialSort !== '' &&
              product.specMap.spec_specialSort !== undefined
            )?_c('div',{staticClass:"specail"},[_vm._v(" 特价 ")]):_vm._e(),_c('img',{staticClass:"picture",attrs:{"src":product.productPicture.split('$$')[0],"alt":product.toCity},on:{"click":function($event){return _vm.to_details({ productNo: product.productNo })}}}),_c('div',{staticClass:"typeText"},[_c('span',{staticClass:"managementTypeText"},[_vm._v(_vm._s(_vm._f("format_managementTypeText")(product.managementType)))]),_c('span',{staticClass:"areaTypeText"},[_vm._v(_vm._s(_vm._f("format_areaType")(product.areaType)))])])]),_c('div',{staticClass:"product_info"},[_c('p',{staticClass:"product_title",on:{"click":function($event){return _vm.to_details({ productNo: product.productNo })}}},[_vm._v(" "+_vm._s(product.productName)+" ")]),_c('div',{staticClass:"info"},[_c('p',{staticClass:"product_tag"},[_c('span',{class:[
                  'productLevel',
                  {
                    scriptures: Number(product.specMap.spec_productLevel) === 1,
                  },
                  {
                    economics: Number(product.specMap.spec_productLevel) === 2,
                  },
                  {
                    promotion: Number(product.specMap.spec_productLevel) === 3,
                  },
                  { exalted: Number(product.specMap.spec_productLevel) === 4 },
                  { best: Number(product.specMap.spec_productLevel) === 6 } ]},[_vm._v(_vm._s(product.specMap.spec_productLeve)+_vm._s(_vm._f("format_productLevel")(product.productLeve)))]),(product.specMap.spec_trafficMode)?_c('span',{staticClass:"trafficMode"},[_vm._v(_vm._s(_vm._f("format_trafficMode")(product.specMap.spec_trafficMode)))]):_vm._e(),(product.specMap.spec_collectGuestType)?_c('span',{staticClass:"collectGuestType"},[_vm._v(_vm._s(_vm._f("format_collectGuestType")(product.specMap.spec_collectGuestType)))]):_vm._e(),(product.specMap.spec_haveSelfItem)?_c('span',{staticClass:"haveSelfItem"},[_vm._v(_vm._s(_vm._f("format_haveSelfItem")(product.specMap.spec_haveSelfItem)))]):_vm._e(),(product.specMap.spec_haveShopping)?_c('span',{staticClass:"haveShopping"},[_vm._v(_vm._s(_vm._f("format_haveShopping")(product.specMap.spec_haveShopping)))]):_vm._e()]),_c('p',[_c('span',{staticClass:"from_city"},[[_vm._v("<"+_vm._s(_vm._f("format_city")(product.fromCity))+"出发>")]],2),_c('span',{staticClass:"productNo span_title"},[_vm._v("编号："+_vm._s(product.productNo))]),(product.specMap.spec_hotelLevel)?_c('span',{staticClass:"hotelLevel"},[_vm._v(_vm._s(_vm._f("format_hotelLevel")(product.specMap.spec_hotelLevel)))]):_vm._e()]),_c('p',[_c('span',{staticClass:"to_city",attrs:{"title":_vm._f("format_city")(product.toCity)}},[_c('span',{staticClass:"span_title"},[_vm._v("目的城市：")]),_vm._v(" "+_vm._s(_vm._f("format_city")(product.toCity))+" ")])]),_c('p',[_c('span',[_c('span',{staticClass:"span_title"},[_vm._v("推荐景点：")]),_vm._v(" "+_vm._s(product.touristSpot)+" ")])]),_c('p',[_c('span',{staticClass:"span_title"},[_vm._v("行程天数："+_vm._s(product.specIntMap.specInt_tripDay)+"天"+_vm._s(product.specIntMap.specInt_tripNight)+"夜")]),(product.areaType == 1)?_c('span',[_c('span',{staticClass:"span_title team_wrap"},[_vm._v("最近团期：")]),_c('span',{staticClass:"team",on:{"click":function($event){return _vm.to_details_team(
                      product.productNo,
                      product.specDateMap.specDate_expirationTime
                    )}}},[_vm._v(_vm._s(_vm.$moment(product.specDateMap.specDate_expirationTime).format('YYYY-MM-DD')))])]):_vm._e(),(
                  !product.isMore &&
                  product.areaType == 1 &&
                  product.limit &&
                  (product.managementType !== '1' ||
                    (product.managementType === '1' && _vm.userInfo.userType !== 7))
                )?_c('span',{staticClass:"more_info",on:{"click":function($event){return _vm.to_more(product, _vm.list)}}},[_c('el-link',{attrs:{"type":"info"}},[_vm._v("更多")])],1):_vm._e()]),_c('div',{staticClass:"reserve_btns_wrap"},[_c('p',[_c('span',{staticClass:"punctuation"},[_vm._v("￥")]),_c('span',{staticClass:"price"},[_vm._v(_vm._s(product.price))])]),(
                  product.areaType == 1 &&
                  product.limit &&
                  (product.managementType !== '1' ||
                    (product.managementType === '1' && _vm.userInfo.userType !== 7))
                )?_c('el-button',{staticClass:"reserve_btn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.to_details({ productNo: product.productNo })}}},[_vm._v("立即预定")]):_vm._e()],1)]),_c('div',[(product.isMore)?_c('div',{staticClass:"product_team"},[_c('div',{staticClass:"team_table"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":product.teamData,"size":"mini","border":""}},[_c('el-table-column',{attrs:{"prop":"teamTime","width":"140","align":"center","label":"出团日期"}}),_c('el-table-column',{attrs:{"align":"center","prop":"haltSaleDateTime","width":"140","label":"截至日期"}}),_c('el-table-column',{attrs:{"align":"center","prop":"adultRetailPrice","label":"建议零售价"}}),_c('el-table-column',{attrs:{"align":"center","prop":"adultPrice","label":"结算价"}}),_c('el-table-column',{attrs:{"align":"center","prop":"totalSeat","label":"总位数"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.inventoryType === 1)?_c('span',[(scope.row.totalSeat >= 9)?[_vm._v("有位")]:[_vm._v(_vm._s(scope.row.totalSeat))]],2):(scope.row.inventoryType === 2)?_c('span',[_vm._v("不限")]):(scope.row.inventoryType === 3)?_c('span',[_vm._v("现询")]):_vm._e()]}}],null,true)}),_c('el-table-column',{attrs:{"align":"center","prop":"emptySeat","label":"余位"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.emptySeat >= 9)?[_vm._v("有位")]:[_vm._v(_vm._s(scope.row.emptySeat))]]}}],null,true)}),_c('el-table-column',{attrs:{"align":"center","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.to_reserve_team(
                            product.productNo,
                            scope.row.teamTime,
                            scope.row.id
                          )}}},[_vm._v("预定")])]}}],null,true)})],1)],1),_c('el-row',[_c('el-button',{attrs:{"type":"info","plain":""},on:{"click":function($event){return _vm.get_more_team(product)}}},[_vm._v("显示更多")])],1),_c('el-row',[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.fold_more(product, _vm.$refs.product[key])}}},[_vm._v(" 收起  "),_c('i',{staticClass:"el-icon-arrow-up"})])],1)],1):_vm._e()])])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }