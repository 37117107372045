import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/yinzhilv/get_user_info'
export default {
  props: {
    list: {
      type: Array,
      requred: true,
    },
    to_details: {
      type: Function,
      requred: true,
    },
    to_more: {
      type: Function,
      requred: true,
    },
    to_reserve_team: {
      type: Function,
      requred: true,
    },
    fold_more: {
      type: Function,
      requred: true,
    },
    get_more_team: {
      type: Function,
      requred: true,
    },
    to_details_team: {
      type: Function,
      requred: true,
    },
  },
  data() {
    return {
      userInfo: {}
    };
  },
  filters: {
    format_cityName (val) {
      const arr = val.split('$$');
      if (arr.length > 2) {
        return `${arr[0]}/${arr[0]}多地`;
      } else {
        return arr.map((item) => item.replace('市', '')).join('/');
      }
    }
  },
  methods: {

  },

  mounted() {
    get_user_info().then(res => {
      this.userInfo = res.userVo
    })
  },
  filters: {
    format_managementTypeText(val) {
      switch (Number(val)) {
        case 1:
          return '跟团游';
          break;
        case 2:
          return '自由行';
          break;
        case 3:
          return '当地游';
          break;
        case 4:
          return '半自助';
          break;
      }
    },
    format_areaType(val) {
      switch (Number(val)) {
        case 1:
          return '国内';
          break;
        case 2:
          return '出境';
          break;
      }
    },

    format_productLevel (val) {
      switch (Number(val)) {
        case 1:
          return '经典';
          break;
        case 2:
          return '经济';
          break;
        case 3:
          return '促销';
          break;
        case 4:
          return '尊享';
          break;
        // case 5:
        //   return '转谈';
        //   break;
        case 6:
          return '臻品';
          break;
      }
    },

    format_trafficMode(trafficMode) {
      switch (trafficMode) {
        case "1":
          return "航空";
          break;
        case "2":
          return "高铁";
          break;
        case "3":
          return "动车";
          break;
        case "4":
          return "火车";
          break;
        case "5":
          return "旅游大巴";
          break;
        case "6":
          return "其他";
          break;
      }
    },
    format_collectGuestType(collectGuestType) {
      switch (collectGuestType) {
        case "1":
          return "散拼团";
          break;
        case "2":
          return "小包团";
          break;
      }
    },
    format_haveSelfItem(haveSelfItem) {
      switch (haveSelfItem) {
        case "1":
          return "有自费";
          break;
        case "2":
          return "无自费";
          break;
      }
    },
    format_haveShopping(haveShopping) {
      switch (haveShopping) {
        case "1":
          return "有购物";
          break;
        case "2":
          return "无购物";
          break;
      }
    },
    format_hotelLevel(hotelLevel) {
      switch (hotelLevel) {
        case "1":
          return "民宿";
          break;
        case "2":
          return "二星级及以下";
          break;
        case "3":
          return "准三星";
          break;
        case "4":
          return "三星级";
          break;
        case "5":
          return "准四星";
          break;
        case "6":
          return "四星级";
          break;
        case "7":
          return "准五星";
          break;
        case "8":
          return "五星";
          break;
        default:
          return "暂无星级";
          break;
      }
    },

    format_city (val) {
      if (val) return val.replace(/\$\$/g, ",");
    }
  },
};
