const Q = require(`q`);
const _ = require(`underscore`);
const __request = require(`./__request`);

// 获取热门景点推荐：http://tour-alpha.yinzhilv.com/doc.html#/yinzhilv_travel/home-config-controller/getHotAttractionsUsingPOST
const web_common_visitor_getHotAttractions = () => {
    const p = {
        method: 'post',
        urlSuffix: '/web/common/visitor/getHotAttractions',
    };
    return Q.when()
        .then(function () {
            return __request(p);
        })
        .then(function (data) {
            return Q.resolve(data);
        })
        .catch(function (ex) {
            console.error(ex);
            if (ex) {
                if (ex.stack) {
                    console.error(ex.stack);
                }
            }
            return Q.reject(ex);
        });
};
module.exports = web_common_visitor_getHotAttractions;